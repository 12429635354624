.root-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.prop-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
  margin-left: 10%;
  margin-right: 10%;
}

.slider-container {
  flex-grow: 10;
  margin-right: 3%;
}

.array-type-menu{
  flex-grow: 1;
}

.sort-button{
  flex-grow: 1;
}

.github-button{
  flex-grow: 1;
}

.top-navbar {
  text-align: left;
  flex-grow: 1;
}

.drawer-list{
  width: 200px;
}